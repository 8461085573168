@import '../../../../styles/mixins/element-mixins';
@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';

.pinkAlertBanner {
  width: 100%;
  height: $alert-banner-height-mobile;
  min-height: $alert-banner-height-mobile;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;

  @include laptop-bp() {
    height: $alert-banner-height;
    min-height: $alert-banner-height;
    padding: 0px 300px;
  }

  &__leftAlign {
    padding: 0px 10px;
  }

  &__twoLine {
    height: $alert-banner-height-two-line-mobile !important;
    min-height: $alert-banner-height-two-line-mobile !important;

    @include laptop-bp() {
      height: $alert-banner-height-two-line !important;
      min-height: $alert-banner-height-two-line !important;
    }
  }
}
.fade {
  transition: opacity 0.5s ease-in-out;
}

.alertBannerMessage {
  white-space: nowrap;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 14px;
    @include laptop-bp() {
      font-size: 18px;
    }
  }

  h2 {
    font-size: 12px;
    @include laptop-bp() {
      font-size: 16px;
    }
  }

  h3 {
    font-size: 11px;
    @include laptop-bp() {
      font-size: 14px;
    }
  }

  h4 {
    font-size: 10px;
    @include laptop-bp() {
      font-size: 12px;
    }
  }
}
