@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/element-mixins';
.breadcrumbPlaceholder {
  min-height: 5vh;
}
.categoryCarouselPlaceholder {
  min-height: 30vh;
}
.findUsBannerPlaceholder {
  min-height: 100vh;

  @include desktop-bp {
    min-height: 30vh;
  }
}
.notFoundPlaceholder {
  min-height: 100vh;

  @include desktop-bp {
    min-height: 50vh;
  }
}
.findUsMapPlaceholder {
  min-height: 30vh;
}
.heroBannerPlaceholder {
  min-height: 100vh;
  @include desktop-bp {
    min-height: 30vh;
  }
}
.plpBannerPlaceholder {
  min-height: 100vh;
  @include desktop-bp {
    min-height: 30vh;
  }
}
.ctaListWrapperPlaceholder {
  min-height: 5vh;
}
.acordionPlaceholder {
  min-height: 5vh;
}
.textComponentPlaceholder {
  min-height: 20vh;
}
.asymetricalContentPlaceholder {
  min-height: 40vh;
}
.contentCarouselPlaceholder {
  min-height: 20vh;
}
.fitAndSizeGuideCollectionPlaceholder {
  min-height: 20vh;
}
.sizeGuideChartPlaceholder {
  min-height: 40vh;
}
.pinkBulletinBannerPlaceholder {
  min-height: 100vh;

  @include desktop-bp {
    min-height: 30vh;
  }
}
.fiftyfiftyPlaceholder {
  min-height: 30vh;
}
.pinkBulletinImagePlaceholder {
  min-height: 30vh;
}
.pinkBulletinTagsPlaceholder {
  min-height: 5vh;
}
.productCarouselPlaceholder {
  min-height: 20vh;
}
.spacerPlacehodler {
  min-height: 5vh;
}
.tableOfContentsPlaceholder {
  min-height: 40vh;
}
.simpleContentPlaceholder {
  min-height: 20vh;
}
.storeServicesPlaceholder {
  min-height: 20vh;
}
.imageComponentPlaceholder {
  min-height: 30vh;
}
